<template>
  <v-card class="accounting-document">
    <v-toolbar color="grey darken-3" dark elevation="1" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-card-text class="pt-2 pb-0">
      <div class="document-header">
        <div class="conducted-box d-flex">
          <v-alert
              dense
              text
              class="mb-0 py-1"
              :color="conducted ? 'success' : 'secondary'"
              :type="conducted ? 'success' : 'warning'"
              style="flex: 1"
          >
            {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
          </v-alert>
        </div>
        <div class="document-header-row px-0 pt-2 pb-2">
          <div style="flex: 0 0 180px; margin-right: 16px">
            <date_input v-model="date" label="Дата: " :time="true" required/>
          </div>
          <div style="flex: 0 0 144px; margin-right: 16px">
            <string_input v-model="number" label="Номер: " :disabled="true"/>
          </div>
          <div style="flex: 0 0 310px; margin-right: 16px">
            <select_input
                v-model="person_id"
                :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                :computed-getter="'get_personal_list_select'"
                :input-value-as-value="false"
                :item-search="'text'"
                :show-on-focus="false"
                :row_num="0"
                label="Працівник"
                :show-select-on-focus="false"
                :action-filter="'@field=type@;@start-with=S@by_contract'"
                :required="true"
                :select-button-object="{
                          name: 'PERSONAL_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список працівників',
                          element_title: 'Працівник',
                          action_filter: '@field=person_type@;@start-with=S@by_contract',
                          show: true
                        }"
                @selectChange="onPersonChange"
            />
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="padding: 5px;">
        <div class="block-divider ml-0 mb-0">
          Табличні дані
        </div>
        <div>
            <div class="document-body-row px-0 pt-2 pb-">
              <div style="flex: 0 0 340px; margin-right: 16px">
                <select_input
                    v-model="subdivision_id"
                    :computed-action="'SELECT_SUBDIVISION_LIST_ELEMENTS'"
                    :computed-getter="'get_subdivision_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Підрозділ"
                    :show-select-on-focus="false"
                    :required="true"
                    :select-button-object="{
                          name: 'SUBDIVISION_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список підрозділ',
                          element_title: 'Підрозділ',
                          show: true
                        }"
                />
              </div>
              <div style="flex: 0 0 310px; margin-right: 16px">
                <select_input
                    v-model="pay_type"
                    :import-getter="{ name: 'contract_pay_type_select' }"
                    :input-value-as-value="false"
                    label="Вид оплати: "
                />
              </div>
            </div>
            <div class="document-body-row px-0 pt-2 pb-">
              <div style="flex: 0 0 340px; margin-right: 16px">
                <date_input label="Дата початку" v-model="date_start" required/>
              </div>
              <div style="flex: 0 0 310px; margin-right: 16px">
                <date_input label="Дата закінчення" v-model="date_end" required/>
              </div>
            </div>
            <div class="document-body-row px-0 pt-2 pb-">
              <div style="flex: 0 0 340px; margin-right: 16px">
                <number_input v-model="sum" label="Сума по договору" required></number_input>
              </div>
              <div style="flex: 0 0 310px; margin-right: 16px">
                <select_input
                    v-model="chart_of_account"
                    :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                    :computed-getter="'get_chart_of_accounts_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    label="Рахунок витрат"
                    :show-select-on-focus="false"
                    :required="true"
                    :action-filter="'@field=type@;@start-with=S@8@@@;@field=type@;@start-with=S@9'"
                    :select-button-object="{
                          name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                          group: '',
                          hierarchy: false,
                          only_groups: false,
                          title: 'Список рахунків бух.обліку',
                          element_title: 'Рахунок бух.обліку',
                          action_filter: '@field=code@;@start-with=S@8@@@;@field=code@;@start-with=S@9',
                          show: true
                        }"
                />
              </div>
            </div>
          </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <template>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew"
               :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
               @click="crud_item" class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Відмінити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
  UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
  REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
  CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import contractByClnAPI from "@/utils/axios/accounting/documents/contract_by_cln";

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";
import {getDateFromString} from "@/utils/icons";

const modalDeleteId = 'contract_by_cln_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Contract_By_Cln.vue",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    date_input: () => import("@/components/accounting/input/document_date_input")
  },
  data() {
    return {
      person_id: this.item.person_id || null,
      person_name: this.item.person_name || "",
      date_start: this.item.date_start || null,
      date_end: this.item.date_end || null,
      subdivision_id: this.item.subdivision_id || null,
      chart_of_account: this.item.chart_of_account || null,
      pay_type: this.item.pay_type || null,
      sum: this.item.sum || 0,
    }
  },
  methods: {
    checkDocument(show = false, table = false) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'person_id': {id: 'person_id', text: 'Не заповнений працівник'},
        'subdivision_id': {id: 'subdivision_id', text: 'Не заповнений підрозділ'},
        'chart_of_account': {id: 'chart_of_account', text: 'Не заповнений рахунок бух.обліку'},
        'pay_type': {id: 'pay_type', text: 'Не заповнений вид оплати'},
        'sum': {id: 'new_chart_of_account_id', text: 'Не заповнена сума по договору'},
      }

      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      if (table) {
        console.log('check table')
      }

      if (this.date && this.hire_date) {
        if (getDateFromString(this.date) < getDateFromString(this.hire_date)) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: 'Дата документа має бути більшою за дату прийняття на роботу'}
          )
        }
      }

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }

    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, { text, color })
            }

          })
    },
    onPersonChange(payload) {
      this.person_name = (payload || {}).text || (payload || {}).full_name || ""
    },
    validateForm(only_bool = false) {
      const required_items = {
        'date': 'Дата',
        'person_id': 'Працівник',
        'date_start': 'Дата початку',
        'date_end': 'Дата закінчення',
        'subdivision_id': 'Підрозділ',
        'pay_type': 'Вид оплати',
        'chart_of_account': 'Рахунок бух.обліку',
        'sum': 'Сума по договору',
      }

      let form_valid = true
      let errors = []

      Object.keys(required_items).forEach(key => {
        if (!this[key]) {
          form_valid = false
          if (!only_bool) {
            errors.push(required_items[key])
          }
        }
      })

      if (only_bool) return form_valid
      let text = 'Виправте помилки: \n' + errors.join(', ') + ' - мають бути заповнені. \n'
      return {
        valid: form_valid,
        text: text
      }

    },
    subdivisionChange(payload) {
      this.subdivision_id = (payload || {})?.value || null
    },

    clearPersonData(payload) {
      this.date = payload.date || null
      this.number = payload.number || null
      this.person_id = payload.person_id || null
      this.person_name = payload.person_name || ""
      this.date_start = payload.date_start || null
      this.date_end = payload.date_end || null
      this.subdivision_id = payload.subdivision_id || null
      this.pay_type = payload.pay_type || null
      this.chart_of_account = payload.chart_of_account || null
      this.sum = payload.sum || 0
      this.conducted = payload.conducted || false
      this.itemId = payload.id || null
    },
    fetch_data_by_id() {
      if (this.isNew) return

      contractByClnAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.conducted = data.conducted || false
            this.date = data.date || null
            this.number = data.number || null
            this.person_id = data.person_id || null
            this.person_name = data.person_name || ''
            this.date_start = data.date_start || null
            this.date_end = data.date_end || null
            this.subdivision_id = data.subdivision_id || null
            this.pay_type = data.pay_type || null
            this.chart_of_account = data.chart_of_account || null
            this.sum = data.sum || null
          })
    },
    load_select_data() {
      return Promise.all([
        this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      ])
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення договору по ЦПХ № ${this.number} (${this.person_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = {
        date: this.date,
        number: this.number,
        person_id: this.person_id,
        subdivision_id: this.subdivision_id,
        date_start: this.date_start,
        date_end: this.date_end,
        pay_type: this.pay_type,
        sum: this.sum,
        chart_of_account: this.chart_of_account
      }
      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.load_select_data()
              this.checkDocument()
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_CONTRACT_BY_CLN_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
            }
          }
      )
    }
  },
}
</script>
